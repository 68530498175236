/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-noninteractive-tabindex */
import clsx from 'clsx'
import type { DependencyList, ReactNode } from 'react'
import { useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { usePreventBackgroundScrollOnModal } from '../../../hooks/usePreventBackgroundScrollOnModal'
import { FocusTrap } from '../focus-trap/FocusTrap'

const WIDTH = {
  small: 'w-[34rem]',
  medium: 'w-[52rem]',
  large: 'w-[60rem]',
  full: 'w-full',
  drawer: 'w-full',
  tooltip: 'w-full',
}

export type ModalSizeOptions = 'small' | 'medium' | 'large' | 'full' | 'drawer' | 'tooltip'

export default function Modal({
  children,
  size = 'full',
  show = true,
  onHide,
  rebuildTabListOnchange = [],
}: {
  children: ReactNode
  size?: ModalSizeOptions
  show?: boolean
  onHide?: () => void
  rebuildTabListOnchange?: DependencyList
}) {
  const portalElement = useMemo(
    () => typeof window !== 'undefined' && document.getElementById('modal'),
    []
  )

  const backgroundPosition = size === 'drawer' ? 'items-end' : 'items-center'
  const modalHeight = size === 'drawer' || size === 'tooltip' ? '' : 'h-full'

  useEffect(() => {
    if (!portalElement && show) {
      console.error('Missing portal element, which means modals will not be visible')
    }
  }, [portalElement, show])

  usePreventBackgroundScrollOnModal(show)

  return show && portalElement
    ? createPortal(
        // HACK: We have to apply an unreasonably high z-index to the modal since the Attentive popup uses an inline & important style for it's z-index.
        <FocusTrap onEscapePress={onHide} rebuildTabListOnchange={rebuildTabListOnchange}>
          <div
            className={clsx(
              'fixed bottom-0 top-0 z-[2147483648] flex h-full w-full justify-center bg-black bg-opacity-20 focus:outline-none',
              backgroundPosition
            )}
            onClick={onHide}
            role="presentation"
            tabIndex={-1}
          >
            <div
              className={clsx(
                `relative z-50 flex flex-col sm:m-4 sm:rounded-md ${WIDTH[size]} max-h-full overflow-y-auto sm:h-auto`,
                size === 'tooltip' && 'mx-2 rounded',
                modalHeight
              )}
              role="dialog"
              aria-labelledby="modal-title"
              aria-modal="true"
              tabIndex={0}
              onClick={(e) => {
                // Prevent propagation to the backdrop
                e.stopPropagation()
              }}
            >
              {children}
            </div>
          </div>
        </FocusTrap>,
        portalElement
      )
    : null
}
