import clsx from 'clsx'
import Image from 'next/legacy/image'

export default function ProductImage(props: {
  src: string
  priority?: boolean
  altTitle: string
  className?: string
}) {
  return (
    <div className={clsx('relative m-2 w-1/3 md:w-36 lg:w-44', props.className)}>
      <Image
        src={props.src}
        layout="fill"
        objectFit="contain"
        priority={props.priority}
        alt={props.altTitle}
      />
    </div>
  )
}
