import clsx from 'clsx'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import LegacyCloseIcon from '../legacy/icons/LegacyCloseIcon'

function DefaultTitle({
  title,
  alignTitleLeft = false,
}: {
  title: ReactNode
  alignTitleLeft?: boolean
}) {
  return (
    <h2 className={clsx('text-xl font-bold md:text-2xl', !alignTitleLeft && 'text-center')}>
      {title}
    </h2>
  )
}

export type ModalHeaderProps = {
  title: ReactNode
  onClose: () => void
  renderTitle?: (title: ReactNode) => ReactNode
  leftButton?: ReactNode
  className?: string
  alignTitleLeft?: boolean
}

export const ModalHeader = forwardRef<HTMLDivElement | null, ModalHeaderProps>(
  (
    {
      title,
      onClose,
      renderTitle = (title, alignTitleLeft) => (
        <DefaultTitle alignTitleLeft={alignTitleLeft} title={title} />
      ),
      leftButton,
      className = '',
      alignTitleLeft = false,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          'flex flex-row',
          alignTitleLeft ? 'justify-between pl-5' : '',
          'items-center border-b border-coolGray-100 bg-gray-50',
          className
        )}
      >
        {!alignTitleLeft ? (
          <div className={clsx('h-14 p-4', leftButton ? 'w-24' : 'w-14')}>{leftButton}</div>
        ) : null}
        <div id="modal-title" className="flex-1 py-2">
          {renderTitle(title, alignTitleLeft)}
        </div>
        <div className={clsx('mr-1 flex justify-end rounded-full', leftButton ? 'w-24' : 'w-14')}>
          <button
            aria-label="close modal"
            type="button"
            className="rounded-full p-3"
            onClick={onClose}
          >
            <LegacyCloseIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
    )
  }
)
