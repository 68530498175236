export default function LegacyCloseIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="-10 0 815 1024">
      <path
        fill="currentColor"
        d="M742 765q0 10 -4.5 20.5t-11.5 17.5l-78 78q-8 8 -18 12t-21 4t-21 -4t-18 -12l-168 -168l-168 168q-7 8 -17.5 12t-21.5 4q-10 0 -20.5 -4t-17.5 -12l-78 -78q-8 -7 -12 -17.5t-4 -20.5q0 -11 4 -21.5t12 -17.5l168 -168l-168 -168q-8 -8 -12 -18t-4 -21t4 -21t12 -18
  l78 -78q7 -7 17.5 -11.5t20.5 -4.5q11 0 21.5 4.5t17.5 11.5l168 168l168 -168q8 -7 18 -11.5t21 -4.5t21 4.5t18 11.5l78 78q7 8 11.5 18t4.5 21t-4.5 21t-11.5 18l-168 168l168 168q7 7 11.5 17.5t4.5 21.5v0z"
      />
    </svg>
  )
}
