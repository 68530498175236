import type { ReactNode } from 'react'
import css from './Modal.module.css'

export type ModalContentProps = {
  className?: string
  children: ReactNode
}

export function ModalContent({ className = '', children }: ModalContentProps) {
  return (
    <div
      className={`flex-1 space-y-5 overflow-y-auto bg-gray-50 p-5 sm:h-auto ${css.ModalContent} ${className}`}
    >
      {children}
    </div>
  )
}
