import type { SubscriptionFrequency } from '../../hooks/pageType/account/autoship/useSubscription'

export const addDays = function (date: Date, days: number) {
  const cloned = new Date(date)
  cloned.setDate(cloned.getDate() + days)
  return new Date(cloned.valueOf())
}
export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const formatDate = (inputDate: Date | string | number, pattern?: string | null): string => {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate)

  if (!date.getDate()) {
    return ''
  }

  if (!pattern) {
    return date.toDateString()
  }

  return pattern
    .replace('dddd', date.getDate().toString().padStart(2, '0')) // 01
    .replace('dd', date.getDate().toString()) // 1
    .replace('DDDD', DAYS[date.getDay()] ?? '') // Monday
    .replace('DD', DAYS[date.getDay()]?.substring(0, 3) ?? '') // Mon
    .replace('Do', getNumberWithOrdinal(date.getDate())) // 1st
    .replace('mmmm', (date.getMonth() + 1).toString().padStart(2, '0')) // 01
    .replace('mm', (date.getMonth() + 1).toString()) // 1
    .replace('MMMM', MONTHS[date.getMonth()] ?? '') // January
    .replace('MM', MONTHS[date.getMonth()]?.substring(0, 3) ?? '') // Jan
    .replace('yyyy', date.getFullYear().toString()) // 1977
}

const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

function getNumberWithOrdinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return n + (s[(v - 20) % 10]! || s[v]! || s[0]!)
}

/**
 * MMM do YYYY
 */
const DATE_FORMAT_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' } as const;
export function formatShortDate(date: Date) {
  return new Intl.DateTimeFormat('en-US', DATE_FORMAT_OPTIONS).format(date)
}

/**
 * MMM do
 */
export function formatShortDateNoYear(date: Date) {
  return `${months[date.getMonth()]} ${date.getDate()}`
}

/**
 * A utility function to determine the difference in days between a specified date
 * and the current day.
 */
export function differenceInDays(inputDate?: Date | string | number) {
  if (!inputDate) return -1

  const date = inputDate instanceof Date ? inputDate : new Date(inputDate)

  const differenceInTime = new Date().getTime() - date.getTime()

  return Math.floor(differenceInTime / (1000 * 3600 * 24))
}

export function calculateNextOrderDate(
  inputDate: string | Date,
  nextFrequency: SubscriptionFrequency,
  currentFrequency?: SubscriptionFrequency
) {
  const currentDate = inputDate instanceof Date ? inputDate : new Date(inputDate)
  let startDate = currentDate

  if (currentFrequency) {
    const { type: prevType, value: prevValue } = currentFrequency
    startDate = determineShipOn(currentDate, { type: prevType, value: -(prevValue ?? 0) })
  }

  return determineShipOn(startDate, nextFrequency)
}

export enum FrequencyType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export function determineShipOn(date: Date, { type, value = 0 }: SubscriptionFrequency) {
  const isMonthEnd = (d: Date): boolean => {
    const d2 = new Date(d)
    d2.setUTCDate(d.getUTCDate() + 1)
    return d.getUTCMonth() !== d2.getUTCMonth()
  }

  let shipOnDate = new Date(date)

  switch (type) {
    case FrequencyType.DAY:
      shipOnDate.setUTCDate(date.getUTCDate() + value)
      break
    case FrequencyType.WEEK:
      shipOnDate.setUTCDate(date.getUTCDate() + value * 7)
      break
    case FrequencyType.MONTH:
      if (isMonthEnd(date)) {
        shipOnDate = new Date(date.getUTCFullYear(), date.getUTCMonth() + value + 1, 0)
      } else {
        shipOnDate = new Date(date.getUTCFullYear(), date.getUTCMonth() + value, date.getUTCDate())
      }
      break
  }

  return shipOnDate
}
