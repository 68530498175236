export interface AccessibleNode extends Node {
  readonly href?: string
  readonly disabled?: boolean
  readonly rel?: string
  readonly tabIndex?: number
  readonly type?: string
}

export const ACCESSIBLE_NODES = [
  'input',
  'select',
  'textarea',
  'a',
  'button',
  '[tabindex]',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
  'details>summary:first-of-type',
  'details',
]
