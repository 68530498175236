import { useCallback, useState } from 'react'

export interface UseMutationOptions<Data = unknown, Args = unknown> {
  onSuccess?: (data: Data, args?: Args) => void
  onError?: (err: unknown, args?: Args) => void
  onStart?: (args?: Args) => void
  onFinish?: (args?: Args) => void
}

/**
 * This hook is used for performing async actions (mutations) after some user action e.g.:
 *  - After a form submission
 *  - When a button is clicked
 *
 * It wraps up loading & error states & returns the resultant data
 *
 * @example
 * const {error, loading, data, mutate} = useMutation(async () => doSomethingAsync())
 *
 * if (error) return <Error/>
 * if (loading) return <LoadingIndicator/>
 * return <Component data={data} onAction={mutate}/>
 *
 * @param fn - An async function that takes arguments & returns data
 * @param onError - Called if fn throws an error
 * @param onSuccess - Called with data returned by fn
 * @param onStart - Called when mutation starts
 * @param onFinish - Called when mutation finishes, error or not
 */
export default function useMutation<Args = unknown, Data = unknown, E = Error>(
  fn: (args?: Args) => Promise<Data>,
  { onError, onSuccess, onFinish, onStart }: UseMutationOptions<Data, Args> = {}
) {
  const [error, setError] = useState<E | null>(null)
  const [data, setData] = useState<Data | null>(null)
  const [loading, setLoading] = useState(false)

  return {
    mutate: useCallback(
      async (args?: Args) => {
        setError(null)
        setData(null)
        setLoading(true)
        onStart?.(args)
        try {
          const data = await fn(args)
          setData(data)
          onSuccess?.(data, args)
        } catch (err) {
          setError(err)
          onError?.(err, args)
        } finally {
          setLoading(false)
          onFinish?.(args)
        }
      },
      [fn, onError, onFinish, onStart, onSuccess]
    ),
    error,
    data,
    loading,
    clearError: () => setError(null),
    reset: () => {
      setError(null)
      setData(null)
      setLoading(false)
    },
  }
}
