import type { RefObject } from 'react'
import type { AccessibleNode } from './accessibleNodes'
import { ACCESSIBLE_NODES } from './accessibleNodes'

export type NodeIsFocusableOptions = {
  ignoreDisabledAttr?: boolean
  ignoreHiddenAttr?: boolean
  ignoreHrefAttr?: boolean
  ignoreTabIndex?: boolean
}

/**
 * A utility function to determine if a node is tabbable and focusable.
 *
 * @param node - a single HTML node
 * @param options - an optional list of options: `ignoreDisabledAttr`, `ignoreHiddenAttr`, `ignoreHrefAttr`, and `ignoreTabIndex`
 */
export function nodeIsAccessible(
  { disabled, href, nodeName, rel, tabIndex, type }: AccessibleNode,
  options: NodeIsFocusableOptions = {}
) {
  if (typeof tabIndex === 'number' && !options.ignoreTabIndex) return tabIndex >= 0
  if (disabled && !options.ignoreDisabledAttr) return false

  switch (nodeName) {
    case 'A':
      return options.ignoreHrefAttr || (!!href && rel !== 'ignore')
    case 'INPUT':
      return options.ignoreHiddenAttr || type !== 'hidden'
    case 'BUTTON':
    case 'SELECT':
    case 'TEXTAREA':
      return true
    default:
      return false
  }
}

export default function findAccessibleNodes(
  ref: RefObject<HTMLElement>,
  options?: NodeIsFocusableOptions
) {
  if (!ref.current) return []
  const nodes = ref.current.querySelectorAll(ACCESSIBLE_NODES.join(','))
  return Array.from(nodes).filter((node) => nodeIsAccessible(node, options)) as HTMLElement[]
}
